//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {};
  },
  props: {
    navList: Array,
    activeNav: String || Number,
    title: String,
    getActive: Function
  },
  methods: {}
};
