import LeftColumn from "@/page/distributor/other/components/leftColumn/1.0.0/index.vue";

export default {
  name: "distributor-FAQ",
  data() {
    return {
      navList: ["常见问题"],
      activeNav: null
    };
  },
  components: { LeftColumn },

  methods: {
    getActive(val) {
      this.activeNav = val;
    }
  },
  created() {
    this.activeNav = this.$route.query.type || 0;
  }
};